import { useState, useEffect } from "react";
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";

// Utils
import {
  dateToISOString,
  calculateTimelineDays,
} from "../../../../../utils/dateUtils";
import classNames from "classnames";
import { nanoid } from "nanoid";

const MedicinePosologiesGrid = ({
    posology,
    isNotBlistable,
    isFuturePosology = false,
    isPreviousPosology = false,
  }) => {
  const daysThreshold = "15";
  const {
    initialDate,
  } = useTreatmentsProvider();

  const _ = require("lodash");

  const [ intakes, setIntakes ] = useState(null);

  const calculatePosologyIntakesArray = (posology) => {
    let _intakes = [];
    let limitColumnGridLine = 0;
    const timelineDays = calculateTimelineDays({
        startDate: initialDate,
        timelineDays: null,
        daysThreshold: daysThreshold,
    });
    if(timelineDays) {
        timelineDays.forEach((timelineDay, index) => {
            const startColumnGridLine = index + 2;
            const dayInterval = posology && posology["day_intervals"] ? posology["day_intervals"].find(
              (item) =>
                dateToISOString(new Date(item.date)) === dateToISOString(timelineDay)
            ) : undefined;

            if (
              dayInterval !== undefined &&
              startColumnGridLine >= limitColumnGridLine
            ) {
              limitColumnGridLine = dayInterval.duration ? startColumnGridLine + dayInterval.duration : 0;

              const data = {
                startColumn: startColumnGridLine,
                endColumn: limitColumnGridLine,
                intakes: dayInterval.intakes,
                isProduced: dayInterval.is_produced,
                ifNeeded: posology.if_needed
              };
              _intakes.push(data);
            }

            if (startColumnGridLine > limitColumnGridLine) {
              limitColumnGridLine = startColumnGridLine;

              const data = {
                startColumn: startColumnGridLine,
                endColumn: startColumnGridLine + 1,
                intakes: {},
                isProduced: false,
                ifNeeded: false
              };
              _intakes.push(data);
            }
          });
    };
    setIntakes([..._intakes]);
  };

  const generateIntakes = () => {
    if(!posology) {
      posology = [
        { day_intervals: [
          { date: dateToISOString(initialDate), intakes: {} }]
        }
      ]
    }

    calculatePosologyIntakesArray(posology);

  };

  useEffect(() => {
    generateIntakes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posology]);

  return (
    <div
      key={nanoid()}
      className="PosologiesGrid ProposalPosolgiesGrid d-grid"
      style={{
        gridColumn: '2/3',
        gridTemplateColumns: 'repeat(15, minmax(80px, 80px))'
      }}
    >
      {
        intakes && intakes.map((int) => (
          <div
            className={classNames([
              "PosologiesGrid__Item text-white p-1",
              _.isEmpty(int.intakes) &&
                "PosologiesGrid__Item--Empty border-start border-end",
            ])}
            key={nanoid()}
            id={int.id}
            style={{
              gridColumn: `${int.startColumn - 1 } / ${int.endColumn - 1}`,
              gridRow: `${int.row} / ${int.row} `,
            }}
          >
            <div
              className={classNames([
                _.isEmpty(int.intakes) && "transparent",
                _.isEmpty(int.intakes) === false && "rounded p-1 h-100 d-flex flex-column justify-content-center",
                _.isEmpty(int.intakes) === false && int.isProduced && "bg-success",
                _.isEmpty(int.intakes) === false && int.ifNeeded && int.isProduced === false && "bg-purple",
                _.isEmpty(int.intakes) === false && isNotBlistable === true && int.ifNeeded === false && int.isProduced === false && "bg-orange",
                _.isEmpty(int.intakes) === false && isNotBlistable === false && int.isProduced === false && int.ifNeeded === false && isPreviousPosology === true && isFuturePosology === false && "bg-previous-treatment",
                _.isEmpty(int.intakes) === false && isNotBlistable === false && int.isProduced === false && int.ifNeeded === false && isFuturePosology === true && isPreviousPosology === false && "bg-future-treatment",
                _.isEmpty(int.intakes) === false && isNotBlistable === false && int.isProduced === false && int.ifNeeded === false && isPreviousPosology === false && isFuturePosology === false && "bg-secondary",
              ])}
            >
              {int &&
                _.isEmpty(int.intakes) === false &&
                Object.entries(int.intakes).map(([key, value]) => {
                  return (
                    <p
                      key={key}
                      className={classNames([
                        "w-100 text-center d-flex",
                        Object.entries(int.intakes).length === 1 && 'mb-0',
                        Object.entries(int.intakes).length > 1 && 'mb-1'
                      ])}
                      style={{
                        lineHeight: "1",
                        letterSpacing: "-1px",
                      }}
                    >
                        <span className="badge bg-white text-body p-1">
                          <small>{key}</small>
                        </span>
                        <span className="badge bg-dark text-white p-1">
                          <small>{value}</small>
                        </span>
                    </p>
                  );
                })}
            </div>
          </div>
        ))
      }
    </div>
  )
};

export default MedicinePosologiesGrid;
