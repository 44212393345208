/**
 * Returns a formated date
 * @param {Date} date
 * @returns {String} DD-MM-YYYY
 */

export function DDMMYYYY(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    month = month.padStart(2, "00");
    day = day.padStart(2, "00");

    return [day, month, year].join('-');
}

/**
 * Returns a formated date
 * @param {Date} date
 * @returns {String} DD-MM-YYYY HH:MM:SS
 */

export function DDMMYYYY_HHMMSS(date) {
    var d = new Date(date),
    month = `${(d.getMonth() + 1)}`.padStart(2, "00"),
    day = `${d.getDate()}`.padStart(2, "00"),
    year = `${d.getFullYear()}`,
    hours = `${d.getHours()}`.padStart(2, "00"),
    minutes = `${d.getMinutes()}`.padStart(2, "00"),
    seconds = `${d.getSeconds()}`.padStart(2, "00");

    return `${[day, month, year].join('-')} ${[hours, minutes, seconds].join(':')}`;

}

/**
 * Returns a date objects from a text date
 * @param {Date} date
 * @returns {String} DD-MM-YYYY
 */

export function DDMMYYYY2Date(dateStr) {
    const dateParts = dateStr.split("-");
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject;
}

/**
 * Returns a formated date
 * @param {Date} date
 * @returns {String} DD-MM-YYYY
 */

export function YYYYMMDD(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 *
 * @param {Date} date
 * @returns  {Array} [date]
 */

export function get14DatesfromToday(date) {
    let arr = [];
    for (let i = 0; i < 15; i++) {
        const days = i; // Days you want to get
        const last = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
        var day = last.getDate();
        var month = last.getMonth() + 1;
        var year = last.getFullYear();
        arr.push(new Date(`${year}/${month}/${day}`));
    }
    return arr;
}

export function getDateFromXdays(date,daysToMove) {
    const _date = new Date(date);
    const newDate = new Date(_date.getTime() + (daysToMove * 24 * 60 * 60 * 1000));
    return newDate;
}

export function dateToISOString(date){
    let stringDate = '';
    stringDate += (date.getFullYear() + '').padStart(4,"0000");
    stringDate += '-';
    stringDate += (date.getMonth() + 1 + '').padStart(2,"00");
    stringDate += '-';
    stringDate += (date.getDate() + '').padStart(2,"00");

    return stringDate;
}

export const addDaysToDate = (date, days) => {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
};

export const calculateTimelineDays = ({startDate,timelineDays,daysThreshold}) => {
    let _timelineDays = timelineDays ? [...timelineDays] : [];
    for (let i = 0; i < daysThreshold; i++) {
        const newDate = addDaysToDate(startDate, i);
        _timelineDays.push(newDate);
    }
    return _timelineDays;
};

