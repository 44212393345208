// Components
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditPosologyModalContent from '../EditPosologiesModalContent';

// Libs
import { withTranslation } from "react-i18next";

// Styles
import './styles.css';

// Context
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";
import useAPI from "../../../../../hooks/useAPI";

// Constants
import LUNCHTIMES_LABELS_POSOLOGY  from '../../../../../constants/LUNCHTIMES_LABELS_POSOLOGY';
import useToastCustom from "../../../../../hooks/useToastCustom";
import { useAppProvider } from "../../../../../context/AppContext";
import { YYYYMMDD } from "../../../../../utils/dateUtils";
import POSOLOGY_TYPES from "../../../../../constants/POSOLOGY_TYPES";

const ModalPosologies = ({
    t,
    show,
    handleClose,
}) => {

    const _ = require("lodash");

    const {
        patterns,
        activeMedicine,
        currentPosology,
        currentPosologyID,
        setDispPosologies,
        setMedicinePosologies,
        setShowPosologyModal,
        setShowPosologiesModal,
        getMedicinesFromPatient,
        posologyTypeSelected,
    } = useTreatmentsProvider();
    const { API, handleReqError } = useAPI();
    const { showSuccessMessage, showErrorMessage } = useToastCustom();
    const { handleLoading, handleNotLoading } = useAppProvider();


    const getAPIPatterns = () => {
        if(!patterns) { return null };
        const previousPatterns = currentPosology.patterns ? currentPosology.patterns : [];
        let result = previousPatterns;
        let data = [];
        if(patterns[0] && patterns[0].intake_time !== undefined) {
            patterns.forEach(pattern => {
                const intake_time = pattern.intake_time;
                Object.entries(pattern.posology).forEach(item => {
                    if(item[1] !== null && item[1] > 0) {
                        const interval = {
                            quantity: item[1],
                            weekday: item[0],
                            intake_time: intake_time
                        };
                        data.push(interval);
                    }

                })
            });
        } else {
            const entries = Object.entries(patterns);
            entries.forEach(entry => {
                const lunchtime = LUNCHTIMES_LABELS_POSOLOGY[entry[0]];
                const entryValues = Object.entries(entry[1]);
                entryValues.forEach(pattVal => {
                    let patt = {
                        quantity: pattVal[1],
                        weekday: pattVal[0],
                        intake_time: lunchtime
                    }
                    if(patt.quantity > 0) {
                        data.push(patt);
                    } else {
                        result = result.filter(el => el.intake_time !== lunchtime)
                    }

                })
            });
        }
        result = _.merge(result,data);
        return result;
    };

    const getPosologies = () => {
        API.get(`posologies/?treatment_medicine=${activeMedicine}`)
        .then((response) => {
            if (response) {
                getMedicinesFromPatient();
                setMedicinePosologies(response.data.results);
                setDispPosologies(response.data.results);
                setShowPosologiesModal(false);
                setShowPosologyModal(false);
            }
        })
        .catch((error) => handleReqError(error));
    }

    const defineMissingMonthlyDates = patterns => {
        let _patterns = [...patterns];
        for (let index = 0; index < patterns.length; index++) {
            let pattern = patterns[index];

            if(pattern['month_start_day'] === undefined){
                pattern['month_start_day'] = "1";
            }

            if(pattern['month_end_day'] === undefined){
                pattern['month_end_day'] = "31";
            }

            _patterns[index] = pattern;
        }

        return _patterns;
    }

    const handleSavePosology = () => {
        handleLoading();
        let {start_date, end_date } = currentPosology;
        start_date = start_date ? YYYYMMDD(start_date) : null;
        end_date = end_date ? YYYYMMDD(end_date) : null;
        const url = currentPosologyID ? `posologies/${currentPosologyID}/` : 'posologies/';
        const method = currentPosologyID ? 'put' : 'post';
        let _patterns = posologyTypeSelected === POSOLOGY_TYPES[0] || posologyTypeSelected === POSOLOGY_TYPES[1] || posologyTypeSelected === POSOLOGY_TYPES[2]
        ?
        getAPIPatterns()
        :
        patterns
        ;
        if(_patterns) {
            // Monthly interval does not need to have defined start and end date on each row, so we define it in case it is not.
            if(posologyTypeSelected === 'monthly') {
                _patterns = defineMissingMonthlyDates(_patterns);
            }
            const data = { ...currentPosology, type: posologyTypeSelected, start_date, end_date, patterns: _patterns };

            API({
                method: method,
                url: url,
                data: data,
            })
                .then((response) => {
                        handleNotLoading();
                        if (response.status === 200 || 201) {
                            showSuccessMessage(t('general.changesSavedProperly'));
                            getPosologies();
                        }
                    })
                .catch((error) => handleReqError(error))
            ;
        } else {
            handleNotLoading();
            showErrorMessage('No hay ninguna dosis activa.')
        }
    };

    return (
        <Modal show={show} onHide={ () => setShowPosologyModal(false) } size={'xl'} animation={false}>
            <Modal.Header closeButton>
            { currentPosologyID ? (
                <Modal.Title>{t('pages.planning.texts.editPosology')}</Modal.Title>
            ) : (
                <Modal.Title>{t('pages.planning.texts.createPosology')}</Modal.Title>
                )
            }
            </Modal.Header>
            <Modal.Body>
                <EditPosologyModalContent />
            </Modal.Body>
            <Modal.Footer>
                <>
                <Button variant="outline-primary" onClick={() => handleClose()}>
                    {t('general.cancel')}
                </Button>
                <Button variant="primary" onClick={() => handleSavePosology()}>
                    {t('general.save')}
                </Button>
                </>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(ModalPosologies);
