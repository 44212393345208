import { useEffect } from "react";
import { useTreatmentsProvider } from "../../../../../context/TreatmentsContext";

// Utils
import {
  dateToISOString,
  calculateTimelineDays,
} from "../../../../../utils/dateUtils";
import classNames from "classnames";
import { nanoid } from "nanoid";

const PosologiesGrid = () => {
  const daysThreshold = "15";
  const {
    intakes,
    setIntakes,
    initialDate,
    medicinePosologies,
    activePatientMedicines,
  } = useTreatmentsProvider();

  let row = 0;
  let isEven = false;

  const _ = require("lodash");

  let _intakes = [];

  const calculatePosologyIntakesArray = (posology) => {
    let limitColumnGridLine = 0;
    const timelineDays = calculateTimelineDays({
        startDate: initialDate,
        timelineDays: null,
        daysThreshold: daysThreshold,
    });
    if(timelineDays) {
        timelineDays.forEach((timelineDay, index) => {
            const startColumnGridLine = index + 2;
            const dayInterval = posology["day_intervals"].find(
              (item) =>
                dateToISOString(new Date(item.date)) === dateToISOString(timelineDay)
            );

            if (
              dayInterval !== undefined &&
              startColumnGridLine >= limitColumnGridLine
            ) {
              limitColumnGridLine = startColumnGridLine + dayInterval.duration;
              const data = {
                startColumn: startColumnGridLine,
                endColumn: limitColumnGridLine,
                intakes: dayInterval.intakes,
                isProduced: dayInterval.is_produced,
                ifNeeded: posology.if_needed,
                row: row,
                isEven: isEven,
                is_blistable: posology.is_blistable
              };
              _intakes.push(data);
            }

            if (startColumnGridLine > limitColumnGridLine) {
              limitColumnGridLine = startColumnGridLine;
              const data = {
                startColumn: startColumnGridLine,
                endColumn: startColumnGridLine + 1,
                intakes: {},
                isProduced: false,
                ifNeeded: false,
                row: row,
                isEven: isEven,
                is_blistable: posology.is_blistable,
              };
              _intakes.push(data);
            }
          });
    };
    setIntakes(_intakes);
  };

  const init = () => {
    setIntakes(null);
    if(activePatientMedicines && activePatientMedicines.length > 0) {
      for (let index = 0; index < activePatientMedicines.length; index++) {
        isEven = !isEven;

        const medicine = activePatientMedicines[index];
        let posologies = medicine.posologies;
        posologies.forEach((pos,index) => {
          const _pos = {...pos, is_blistable: medicine.is_blistable};
          posologies[index] = _pos;
        });

        if(!posologies.length) {
          posologies = [
            { day_intervals: [
              { date: dateToISOString(initialDate), intakes: {} }]
            }
          ]
        }

        for (let _index = 0; _index < posologies.length; _index++) {
          row = row + 1;
          const posology = posologies[_index];
          calculatePosologyIntakesArray(posology);
        }
      }
    }
  };

  useEffect(() => {
    if (activePatientMedicines) {
      init();
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePatientMedicines, medicinePosologies]); // filtered ??

  return (
    intakes && intakes.map((int) => (
        <div
          className={classNames([
            "PosologiesGrid__Item text-white p-1",
            _.isEmpty(int.intakes) &&
              "PosologiesGrid__Item--Empty border-start border-end",
          ])}
          key={nanoid()}
          id={int.id}
          style={{
            gridColumn: `${int.startColumn} / ${int.endColumn}`,
            gridRow: `${int.row + 1} / ${int.row + 1} `,
          }}
        >
          <div
            className={classNames([
                _.isEmpty(int.intakes) && "transparent",
                _.isEmpty(int.intakes) === false && "rounded p-1 h-100 d-flex flex-column justify-content-center",
                _.isEmpty(int.intakes) === false && int.isProduced && "bg-success",
                _.isEmpty(int.intakes) === false && int.ifNeeded && !int.isProduced && "bg-purple",
                _.isEmpty(int.intakes) === false && !int.is_blistable && !int.ifNeeded && !int.isProduced && "bg-orange",
                _.isEmpty(int.intakes) === false && !int.isProduced && int.is_blistable && !int.ifNeeded && !int.isProduced && "bg-secondary",
            ])}
          >
            {int &&
              _.isEmpty(int.intakes) === false &&
              Object.entries(int.intakes).map(([key, value]) => {
                return (
                  <p
                    key={nanoid()}
                    className={classNames([
                      "w-100 text-center d-flex",
                      Object.entries(int.intakes).length === 1 && 'mb-0',
                      Object.entries(int.intakes).length > 1 && 'mb-1'
                    ])}
                    style={{
                      lineHeight: "1",
                      letterSpacing: "-1px",
                    }}
                  >
                      <span className="badge bg-white text-body p-1">
                        <small>{key}</small>
                      </span>
                      <span className="badge bg-dark text-white p-1">
                        <small>{value}</small>
                      </span>
                  </p>
                );
              })}
          </div>
        </div>
    ))
  );
};

export default PosologiesGrid;
